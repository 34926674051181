<template>
  <home-page :page_data="page_data"/>
</template>
<script>
import HomePage from "@/components/HomePage";
export default {
  components:{HomePage},
  data:() => ({
    page_data: {
        page_name: '/',
        first_section: null,
        two_section: null,
        three_section: null,
        first_image: null,
        two_image: null,
        three_image: null,
        head: '  عامل توصيل بيتزا يربح 690,000 ريال سعودي في شهر واحد ! ',
      }
  }),
  mounted() {
    document.title = this.page_data.head;
  }
}
</script>